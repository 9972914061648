<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Doctors</h4>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table mb-0 table-borderless">
                    <thead>
                    <tr>
                      <th scope="col">Doctor</th>
                      <th scope="col">Name </th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Department</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="doctor in doctors"  :key="doctor.provider_id">
                      <td class="text-center">
                        <img class="rounded-circle img-fluid avatar-40" src="../../assets/images/user/01.jpg" alt="profile">
                      </td>
                      <td>{{ doctor.first_name }} {{ doctor.last_name }}</td>
                      <td>
                        {{ doctor.phone_number }}
                      </td>
                      <td>
                        {{ doctor.email }}
                      </td>
                      <td>
                        {{ doctor.department }}
                      </td>
                      <td>
                        <b-badge :class="['border mr-1', doctor.profile_status === 1 ? 'border-success text-success' : 'border-danger text-danger']" variant="none">{{ DOCTOR_STATUS_MAPPED.get(doctor.profile_status) }}</b-badge>
                      </td>
                      <td>
                        <b-button variant="outline-primary" class="mr-2"  @click="() => navigateToDoctorProfile(doctor.provider_id)"><i class="ri-information-line"></i> Details</b-button>
                        <b-button variant="outline-danger" class="mr-2" @click="showModal(doctor.patient_id, doctor.first_name)"><i class="ri-delete-bin-line"></i> Delete</b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal ref="delete-confirmation" size="sm" hide-footer title="Delete user">
      <p>Please confirm if you would like to delete the Doctor <b>{{ selectedDoctor.name }}</b></p>
      <b-button class="mt-3" variant="outline-primary" block @click="cancelDeletion">Cancel</b-button>

      <b-overlay
        :show="deleting"
        rounded="lg"
        opacity="0.2"
        spinner-small
        spinner-variant="danger"
        @hidden="onHidden"
        bg-color="red"
      >
        <b-button class="mt-3" variant="outline-danger" block @click="confirmDeletion">Confirm</b-button>
      </b-overlay>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import IqCard from '@components/xray/cards/iq-card'
import { DOCTOR_STATUS_MAPPED } from '@constants/doctor'
import ProviderService from '@services/provider'

export default {
  name: 'Doctors',
  components: { IqCard },

  data () {
    return {
      doctors: [],
      DOCTOR_STATUS_MAPPED,
      selectedDoctor: {
        name: '',
        provider_id: ''
      },
      deleting: false
    }
  },
  mounted () {
    xray.index()
    ProviderService.get_all_provider_accounts()
      .then((response) => {
        this.doctors = response.data
      })
      .catch((error) => {
        console.error('error : ', error)
      })
      .finally(() => {
        console.log('execute finally')
      })
  },

  methods: {
    navigateToDoctorProfile (id) {
      this.$router.push(`doctors/profile/${id}`)
    },
    showModal (id, name) {
      this.$refs['delete-confirmation'].show()
      this.selectedDoctor.name = name
      this.selectedDoctor.patient_id = id
    },
    cancelDeletion () {
      this.$refs['delete-confirmation'].hide()
      this.selectedDoctor.name = ''
      this.selectedDoctor.patient_id = ''
    },
    removePatient () {
      const indexToDelete = this.doctors.findIndex(doctor => doctor.provider_id === this.selectedDoctor.provider_id)

      if (indexToDelete !== -1) {
        this.patients.splice(indexToDelete, 1)
      }
    },
    onHidden () {
      this.$refs.button.focus()
    },
    confirmDeletion () {
      this.deleting = true
      ProviderService.delete_provider(this.selectedDoctor.provider_id)
        .then(() => {
          this.$refs['delete-confirmation'].hide()
          this.removePatient()
          this.deleting = false
          this.selectedDoctor.name = ''
          this.selectedDoctor.provider_id = ''
        })
        .catch((error) => {
          console.error('error deleting provider : ', error)
        })
        .finally(() => {
          console.log('executed finally ')
        })
    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
