<template>
  <div>
    <h1 class="mb-0">Sign in</h1>
    <p>Enter your email address and password to access admin panel.</p>
    <sign-in-form formType="jwt" email="" password=""></sign-in-form>
  </div>
</template>

<script>
import auth0 from 'auth0-js'
import SignInForm from './Forms/SignInForm'
import constant from '../../../config/constant'
export default {
  name: 'SignIn1',
  components: { SignInForm },
  data: () => ({}),
  mounted () {
    const loggedIn = localStorage.getItem('access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ path: '/dashbaord/home-1' })
    }
  },
  methods: {
    loginOAuth0: function () {
      new auth0.WebAuth(constant.auth0Config).authorize()
    }
  }
}
</script>
