import apiSecureClient from '../config/apiSecureClient'

export default {
  update_email_verified (payload) {
    return apiSecureClient.post('/provider/update_provider_email_verified', payload)
  },
  update_signature_verified (payload) {
    return apiSecureClient.post('/provider/update_provider_signature_verified', payload)
  },
  update_documents_verified (payload) {
    return apiSecureClient.post('/provider/update_provider_documents_verified', payload)
  },
  get_all_provider_accounts () {
    return apiSecureClient.get('provider/get_all_providers')
  },
  get_provider_count () {
    return apiSecureClient.get('provider/get_count_providers')
  },
  delete_provider (ProviderId) {
    return apiSecureClient.put('admin/delete_provider_account', { provider_id: ProviderId })
  }
}
