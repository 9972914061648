import axios from 'axios'
const apiClient = axios.create({
  baseURL: 'https://web-api.mypockethealth.co.za/', // "http://127.0.0.1:8000/",
  // baseURL: 'http://127.0.0.1:3000/',
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
export default apiClient
