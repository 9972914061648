<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Voucher List</h4>
              </template>
              <template v-slot:headerAction>
                <tab-nav :pills="true" id="voucher-tab" class="mt-1 mr-5">
                  <tab-nav-items :active="true" id="active-vouchers-tab" ariaControls="active" role="tab" :ariaSelected="true" title="Active" />
                  <tab-nav-items :active="false" id="used-vouchers-tab" ariaControls="used" role="tab" :ariaSelected="false" title="Used" />
                </tab-nav>
                <b-button @click.prevent="generateZltoVoucher()" variant="warning" class="mt1">Add Voucher</b-button>
              </template>
              <template v-slot:body>
                <tab-content id="vouche-content">
                  <tab-content-item :active="true" id="active" aria-labelled-by="active-vouchers-tab">
                    <div class="table-responsive">
                      <table class="table mb-0 table-borderless">
                        <thead>
                        <tr>
                          <th scope="col">Voucher ID</th>
                          <th scope="col">Voucher Code </th>
                          <th scope="col">Doctor</th>
                          <th scope="col">Patient</th>
                          <th scope="col">Status</th>
                          <th scope="col">Last Update</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in voucherList" :key="item['voucher_id']">
                              <td v-if="item['voucher_status'] == 1" >{{ item['voucher_id'] }}</td>
                              <td v-if="item['voucher_status'] == 1" >{{  item['voucher_code'] }}</td>
                              <td v-if="item['voucher_status'] == 1" >{{  item['provider_full_name'] }}</td>
                              <td v-if="item['voucher_status'] == 1" >{{  item['patient_full_name'] }}</td>
                              <td v-if="item['voucher_status'] == 1">
                                <b-tag variant="success">{{ item['voucher_status'] == 0 ? 'Available' : 'Used' }}</b-tag>
                              </td>
                              <td v-if="item['voucher_status'] == 1">{{ $date(item['updated_at']).format('MMM D, YYYY')   }}</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab-content-item>
                  <tab-content-item :active="false" id="used" aria-labelled-by="used-vouchers-tab">
                    <div class="table-responsive">
                      <table class="table mb-0 table-borderless">
                        <thead>
                        <tr>
                          <th scope="col">Voucher ID</th>
                          <th scope="col">Voucher Code </th>
                          <th scope="col">Doctor</th>
                          <th scope="col">Patient</th>
                          <th scope="col">Status</th>
                          <th scope="col">Last Update</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in voucherList" :key="item['voucher_id']">
                          <td v-if="item['voucher_status'] == 0" >{{ item['voucher_id'] }}</td>
                          <td v-if="item['voucher_status'] == 0" >{{  item['voucher_code'] }}</td>
                          <td v-if="item['voucher_status'] == 0" >{{  item['provider_full_name'] }}</td>
                          <td v-if="item['voucher_status'] == 0" >{{  item['patient_full_name'] }}</td>
                          <td v-if="item['voucher_status'] == 0">
                            <b-tag variant="danger">{{ item['voucher_status'] == 1 ? 'Available' : 'Used' }}</b-tag>
                          </td>
                          <td v-if="item['voucher_status'] == 0">{{ $date(item['updated_at']).format('MMM D, YYYY')   }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab-content-item>
                </tab-content>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import VoucherService from '../../services/vouchers'
export default {
  name: 'Doctors',
  components: { IqCard },
  mounted () {
    xray.index()
    this.listZltoVouchers()
  },
  data () {
    return {
      voucherList: []
    }
  },
  methods: {
    listZltoVouchers () {
      VoucherService.list_all_vouchers()
        .then((voucherResponse) => {
          console.log('voucherResponse : ', voucherResponse.data)
          this.voucherList = voucherResponse.data
        })
        .catch((error) => {
          console.log('voucher list error : ', error)
        })
    },
    successToast () {
      this.$bvToast.toast('Voucher code generated', {
        title: `Voucher Created`,
        variant: 'success',
        solid: true
      })
    },
    errorToast () {
      this.$bvToast.toast('Error Voucher code generated', {
        title: `Error Voucher Created`,
        variant: 'danger',
        solid: true
      })
    },
    generateZltoVoucher () {
      VoucherService.generate_zlto_voucher()
        .then((response) => {
          console.log('response : ', response)
          this.listZltoVouchers()
          this.successToast()
        })
        .catch((error) => {
          console.log('error : ', error)
          this.errorToast()
        })
    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
