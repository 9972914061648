<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" autocomplete="off" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="name" name="Full Name" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="name">Your Full Name</label>
          <input type="text" v-model="user.name" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="name" aria-describedby="emailHelp" placeholder="Your Full Name">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="name" name="Email" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="email">Email address</label>
          <input type="email" v-model="user.email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="email" aria-describedby="emailHelp" placeholder="Enter email">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" v-model="user.password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="password" placeholder="Enter Password">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input v-model="accepted_tnc" type="checkbox" class="custom-control-input" :id="formType">
          <label class="custom-control-label" :for="formType">I accept <a href="#">Terms and Conditions</a></label>
        </div>
        <button :disabled="!accepted_tnc" type="submit" class="btn btn-primary float-right">Sign Up</button>
      </div>
      <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Already Have Account ?
              <router-link  to="/dark/sign-in" class="iq-waves-effect pr-4" v-if="$route.meta.dark">
                Sign in
              </router-link>
            <router-link to="/sign-in" class="iq-waves-effect pr-4" v-else>
                Sign in
              </router-link>
          </span>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Auth from '../../../../services/auth'
export default {
  name: 'SignUpForm',
  props: ['formType'],
  computed: {
    ...mapMutations({
      setToken: 'User/setToken',
      setAccount: 'User/setAccount'
    }),
    ...mapActions({
      setTokenAction: 'User/setTokenAction',
      setAccountAction: 'User/setAccountAction'
    }),
    ...mapGetters({
      users: 'Setting/usersState'
    })
  },
  data: () => ({
    accepted_tnc: false,
    user: {
      name: '',
      email: '',
      password: ''
    }
  }),
  methods: {
    onSubmit () {
      // register admin account
      const pieces = this.user.name.split(' ')
      const signupPayload = {
        first_name: pieces[0],
        last_name: pieces[1],
        admin_username: this.user.email,
        admin_password: this.user.password
      }
      console.log('payload  : ', signupPayload)
      Auth.register(signupPayload).then((response) => {
        if (response.status === 200) {
          const token = response.data.accessToken
          this.$store.dispatch('setTokenAction', token)
          const account = response.data.account
          this.$store.dispatch('setAccountAction', account)
          this.login()
        }
      }).catch((error) => {
        console.error('admin register error : ', error)
      })
    },
    login () {
      this.$router.push({ name: 'dashboard.home' })
    }
  }
}
</script>
