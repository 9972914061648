<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Patients</h4>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table mb-0 table-borderless">
                    <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Age</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">City</th>
                      <th scope="col">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="patient in patients"  :key="patient.patient_id">
                      <td>{{ patient.first_name }} {{ patient.last_name }}</td>
                      <td>
                        {{ patient.patient_age }}
                      </td>
                      <td>
                        {{ patient.phone_number }}
                      </td>
                      <td>
                        {{ patient.email }}
                      </td>
                      <td>
                        {{ patient.city }}
                      </td>
                      <td>
                        <b-badge :class="['border mr-1', patient.profile_status === 1 ? 'border-success text-success' : 'border-danger text-danger']" variant="none">{{ PATIENT_STATUS_MAPPED.get(patient.profile_status) }}</b-badge>
                      </td>
                      <td>
                        <div class="flex">
                          <b-button variant="outline-primary" class="mr-2"  @click="() => navigateToPatientProfile(patient.patient_id)"><i class="ri-information-line"></i> Details</b-button>
                          <b-button @click="showModal(patient.patient_id, patient.first_name)"  variant="outline-danger" class="mr-2"><i class="ri-delete-bin-line"></i> Delete</b-button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
      <b-modal ref="delete-confirmation" size="sm" hide-footer title="Delete user">
        <p>Please confirm if you would like to delete the Patient <b>{{ selectedPatient.name }}</b></p>
        <b-button class="mt-3" variant="outline-primary" block @click="cancelDeletion">Cancel</b-button>

        <b-overlay
          :show="deleting"
          rounded="lg"
          opacity="0.2"
          spinner-small
          spinner-variant="danger"
          @hidden="onHidden"
          bg-color="red"
        >
          <b-button class="mt-3" variant="outline-danger" block @click="confirmDeletion">Confirm</b-button>
        </b-overlay>
      </b-modal>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import IqCard from '@components/xray/cards/iq-card'
import { PATIENT_STATUS_MAPPED } from '@constants/patient'
import PatientService from '@services/patient'

export default {
  name: 'Patients',
  components: { IqCard },

  data () {
    return {
      patients: [],
      PATIENT_STATUS_MAPPED,
      selectedPatient: {
        name: '',
        patient_id: ''
      },
      deleting: false
    }
  },
  mounted () {
    xray.index()

    PatientService.get_all_patient_accounts()
      .then((response) => {
        this.patients = response.data
      })
      .catch((error) => {
        console.error('error : ', error)
      })
      .finally(() => {
        console.log('execute finally')
      })
  },

  methods: {
    navigateToPatientProfile (id) {
      this.$router.push(`patients/profile/${id}`)
    },
    showModal (id, name) {
      this.$refs['delete-confirmation'].show()
      this.selectedPatient.name = name
      this.selectedPatient.patient_id = id
    },
    cancelDeletion () {
      this.$refs['delete-confirmation'].hide()
      this.selectedPatient.name = ''
      this.selectedPatient.patient_id = ''
    },
    removePatient () {
      const indexToDelete = this.patients.findIndex(patient => patient.patient_id === this.selectedPatient.patient_id)

      if (indexToDelete !== -1) {
        this.patients.splice(indexToDelete, 1)
      }
    },
    onHidden () {
      this.$refs.button.focus()
    },
    confirmDeletion () {
      this.deleting = true
      PatientService.delete_patient(this.selectedPatient.patient_id)
        .then(() => {
          this.$refs['delete-confirmation'].hide()
          this.removePatient()
          this.deleting = false
          this.selectedPatient.name = ''
          this.selectedPatient.patient_id = ''
        })
        .catch((error) => {
          console.error('error deleting patient: ', error)
        })
        .finally(() => {
          console.log('executed finally ')
        })
    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
