<template>
  <b-container fluid>
    <form>
      <b-row>
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-3 p-0" :active="true" href="#personal-information"
                    title="Personal Information" />
                  <tab-nav-items class="col-md-3 p-0" :active="false" href="#consultation-notes"
                    title="Consultation Notes" />
                  <tab-nav-items class="col-md-3 p-0" :active="false" href="#prescriptions" title="Prescriptions" />
                  <tab-nav-items class="col-md-3 p-0" :active="false" href="#sick-notes" title="Sick notes" />
                  <tab-nav-items class="col-md-3 p-0" :active="false" href="#referral-notes" title="Referral notes" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="iq-edit-list-data">
            <tab-content id="pills-tabContent-2">
              <tab-content-item :active="true" id="personal-information">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Personal Information</h4>
                  </template>
                  <template v-slot:body>
                    <!-- <b-form-group class="row align-items-center">
                      <b-col md="12">
                        <div class="profile-img-edit">
                          <b-img v-if="user.profileImage" :src="user.profileImage"
                            class="profile-pic height-150 width-150" alt="profile-pic" />
                          <b-img v-else src="../../assets/images/user/1.jpg" class="profile-pic height-150 width-150"
                            alt="profile-pic" />
                          <input type="hidden" v-model="user.profileImage">
                          <div class="p-image">
                            <div class="position-relative">
                              <i class="ri-pencil-line upload-button"></i>
                              <b-form-file class="h-100 position-absolute" accept="image/*" style="opacity: 0;"
                                @change="previewImage"></b-form-file>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-form-group> -->
                    <!-- <b-form-group class="col-sm-12" label="Bio:">
                      <b-form-textarea name="bio" v-model="user.bio" style="line-height: 22px;" rows="5">
                      </b-form-textarea>
                    </b-form-group> -->

                    <b-row align-v="center">
                      <div class="col-md-12 my-3">
                        <h4>Patient Info:</h4>
                      </div>
                      <b-form-group class="col-md-6" label="First Name" label-for="first_name">
                        <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.first_name" type="text" placeholder="First Name"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Last Name" label-for="last_name">
                        <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.last_name" type="text" placeholder="Last Name"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Username" label-for="username">
                        <ValidationProvider name="Patient name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.username" type="text" placeholder="Username"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Patient I.D" label-for="patient_id">
                        <ValidationProvider name="Patient I.D" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.patient_id" type="text" placeholder="I.D"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Patient Age" label-for="patient_age">
                        <ValidationProvider name="Patient Age" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.patient_age" type="text" placeholder="Age"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Phone" label-for="phone_number">
                        <ValidationProvider name="User name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.phone_number" type="text" placeholder="Phone"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <div class="col-md-12  my-3">
                        <h4>Address Info:</h4>
                      </div>
                      <b-form-group class="col-md-12" label="Address" label-for="address">
                        <b-form-input v-model="user.address" type="text" placeholder="address"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-6" label="City" label-for="city">
                        <b-form-input v-model="user.city" type="text" placeholder="City"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-6" label="Postal code" label-for="postal_code">
                        <b-form-input v-model="user.postal_code" type="text" placeholder="postal_code"></b-form-input>
                      </b-form-group>
                      <div class="col-md-12  my-3">
                        <h4>Account Status:</h4>
                      </div>
                      <b-form-group class="col-sm-8" label-for="profile_status" label="Profile activation status">
                        <b-form-select plain v-model="user.profile_status" :options="profile_status">
                        </b-form-select>
                      </b-form-group>
                      <b-form-group class="col-sm-4" label-for="profile_status" label="Set Status">
                        <b-button type="button" variant="success" class="mr-2" @click="handleUpdateProfile">Update Profile Status</b-button>
                      </b-form-group>

                      <b-form-group class="col-sm-12" label-for="corporate_client" label="Corporate Account Status">
                        <b-form-select plain v-model="user.corporate_client" :options="corporate_client">
                        </b-form-select>
                      </b-form-group>
                    </b-row>

                    <b-button type="submit" variant="primary" class="mr-2" @click="handleUpdate">Submit</b-button>
                    <b-button type="reset" variant="none" class="iq-bg-danger">Cancel</b-button>
                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="consultation-notes">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Issued Consultation Notes</h4>
                  </template>
                  <template v-slot:body>
                    <table class="table mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date issued</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> dummy data</td>
                          <td>
                            dummy data
                          </td>
                          <td>
                            <div class="flex">
                              <b-button href="#" block variant="primary">Download</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="prescriptions">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Issued Prescriptions</h4>
                  </template>
                  <template v-slot:body>
                    <table class="table mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date issued</th>
                          <th scope="col">Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> dummy data</td>
                          <td>
                            dummy data
                          </td>
                          <td>
                            <div class="flex">
                              <b-button href="#" block variant="primary">Download</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="sick-notes">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Issued Sick Notes</h4>
                  </template>
                  <template v-slot:body>
                    <table class="table mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date issued</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> dummy data</td>
                          <td>
                            dummy data
                          </td>
                          <td>
                            <div class="flex">
                              <b-button href="#" block variant="primary">Download</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="referral-notes">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Issued Referral Notes</h4>
                  </template>
                  <template v-slot:body>
                    <table class="table mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date issued</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> dummy data</td>
                          <td>
                            dummy data
                          </td>
                          <td>
                            <div class="flex">
                              <b-button href="#" block variant="primary">Download</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </template>
                </iq-card>
              </tab-content-item>
            </tab-content>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import apiClient from '@config/apiClient'
import PatientService from '../../services/patient'
export default {
  name: 'Patient-profile',
  mounted () {
    xray.index()
    apiClient.post('admin/get_patient_account', {
      patient_id: this.$route.params.id
    }).then(response => (this.user = response.data))
  },
  data () {
    return {
      user: {},
      currentPassword: '',
      medical_aid_status: [
        { value: 0, text: 'In-active' },
        { value: 1, text: 'Active' }
      ],
      profile_status: [
        { value: 0, text: 'In-active' },
        { value: 1, text: 'Active' }
      ],
      corporate_client: [
        { value: 'no', text: 'In-active' },
        { value: 'yes', text: 'Active' }
      ]
    }
  },
  methods: {
    previewImage: function (event) {
      const input = event.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    changePassword: function () {
    },
    handleUpdateProfile: function () {
      let payload = {
        patient_id: parseInt(this.$route.params.id),
        profile_status: parseInt(this.user.profile_status)
      }
      PatientService.update_patient_profile_status(payload)
        .then((response) => {
          console.log('patient service response : ', response)
        })
        .catch((error) => {
          console.log('patient status update error : ', error)
        })
    },
    handleUpdate: function () {
      apiClient.patch('admin/update_patient_account', {
        patient_id: this.$route.params.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        phone_number: this.user.phone_number,
        age: this.user.patient_age,
        city: this.user.city,
        address: this.user.address,
        postal_code: this.user.postal_code,
        profile_status: this.user.profile_status,
        medical_aid_status: this.user.medical_aid_status,
        corporate_account_status: this.user.corporate_client
      }).then(response => console.log('>>>', response))
    }
  }
}
</script>
