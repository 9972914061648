<template>

  <ValidationObserver ref="form" v-slot="{ handleSubmit }">

    <form class="mt-4" autocomplete="off" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group ">
          <label for="emailInput">Email address</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.email" placeholder="Enter email" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <router-link to="/password-reset" class="float-right">
            Forgot password?
          </router-link>
          <input type="password"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" placeholder="Password" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType">
          <label class="custom-control-label" :for="formType">Remember Me</label>
        </div>
        <button type="submit" class="btn btn-primary float-right">Sign in</button>
      </div>
      <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Don't have an account?
            <router-link to="/dark/sign-up" class="iq-waves-effect pr-4" v-if="$route.meta.dark">
              Sign up
            </router-link>
            <router-link to="/sign-up" class="iq-waves-effect pr-4" v-else>
              Sign up
            </router-link>
          </span>
      </div>
    </form>

  </ValidationObserver>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Auth from '../../../../services/auth'
export default {
  name: 'SignInForm',
  props: ['formType', 'email', 'password'],
  data: () => ({
    user: {
      email: '',
      password: ''
    }
  }),
  mounted () {
    console.log('clearing store')
    this.$store.dispatch('setTokenAction', null)
    this.$store.dispatch('setAccountAction', [])
    this.user.email = this.$props.email
    this.user.password = this.$props.password
  },
  computed: {
    ...mapMutations({
      setToken: 'User/setToken',
      setAccount: 'User/setAccount'
    }),
    ...mapActions({
      setTokenAction: 'User/setTokenAction',
      setAccountAction: 'User/setAccountAction'
    }),
    ...mapGetters({
      stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    onSubmit () {
      // do api call if success fo login else display message
      const loginPayload = {
        username: this.user.email,
        password: this.user.password
      }
      Auth.login(loginPayload).then((response) => {
        console.log('login response : ', response)
        // check if success then do route change after setting store
        if (response.status === 200) {
          const token = response.data.accessToken
          console.log('token : ', token)
          this.$store.commit('setToken', token)
          const account = response.data.account
          console.log(';account : ', account)
          this.$store.commit('setAccount', account)
          this.login()
        }
      }).catch((error) => {
        console.log('login error : ', error)
      })
    },
    login () {
      this.$router.push({ name: 'dashboard.home' })
    }
  }
}
</script>
