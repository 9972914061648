<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Booking History</h4>
              </template>
              <template v-slot:headerAction>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table mb-0 table-borderless">
                    <thead>
                    <tr>
                      <th scope="col">Booking Date</th>
                      <th scope="col">Booking Slot </th>
                      <th scope="col">Booking Status </th>
                      <th scope="col">Booking Rate </th>
                      <th scope="col">Payment Method</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in history" :key="'atr_'+index">
                      <td>{{ format(new Date(item?.slot_date), 'MM/dd/yyyy') }}</td>
                      <td>{{ item?.slot_time }}</td>
                      <td>
                        <b-badge class="border mr-1 border-success text-success" variant="none">{{ item?.booking_status }}</b-badge>
                      </td>
                      <td>R{{ formatCurrency(Number(item?.booking_rate)) }}</td>
                      <td>{{ item?.payment_method }}</td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { format } from 'date-fns'
import { xray } from '../../config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import AdminService from '../../services/admin'

export default {
  name: 'Booking-history',
  components: { IqCard },
  mounted () {
    xray.index()
    this.getBookingAppointments()
  },
  data () {
    return {
      history: []
    }
  },
  methods: {
    format,
    getBookingAppointments () {
      AdminService.get_appointment_history().then(({ data }) => {
        this.history = data
      }).catch((error) => {
        console.error('error: ', error)
      })
    },
    formatCurrency (amount) {
      if (typeof amount !== 'number' || isNaN(amount)) {
        return 'Invalid Amount'
      }
      return amount.toFixed(2)
    }
  }
}
</script>
<style>
.iq-card-body{
  flex: unset;
}
</style>
