import apiSecureClient from '../config/apiSecureClient'
export default {
  list_all_vouchers () {
    return apiSecureClient.get('/voucher/list')
  },
  get_voucher_count () {
    return apiSecureClient.get('voucher/get_count_vouchers')
  },
  generate_zlto_voucher () {
    const payload = {
      voucher_type: 1
    }
    return apiSecureClient.post('/voucher/generate', payload)
  }
}
