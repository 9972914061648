import apiClient from '../config/apiClient'

export default {
  login (userData) {
    return apiClient.post('/admin/login', userData)
  },
  register (userData) {
    return apiClient.post('/admin/signUp', userData)
  }
}
