<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-row>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="ri-user-fill"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{  doctorCount  }}</span></h2>
                    <h5 class="">Doctors</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
              <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-warning"><i class="ri-women-fill"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ voucherCount  }}</span></h2>
                    <h5 class="">Vouchers</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
              <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-danger"><i class="ri-group-fill"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{  patientCount  }}</span></h2>
                    <h5 class="">Patients</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
              <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-info"><i class="ri-hospital-line"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ bookingCount }}</span></h2>
                    <h5 class="">Bookings</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Latest Bookings</h4>
          </template>
          <template v-slot:headerAction>
            <b-link class="text-primary float-right" v-b-toggle.collapse-2>
              <i class="ri-code-s-slash-line" />
            </b-link>
          </template>
          <template v-slot:body>
            <p>No Bookings</p>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">User Summary</h4>
          </template>
          <template v-slot:body>
            <p>No User Summary Data</p>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import IqCard from '@components/xray/cards/iq-card'
import ProviderService from '../services/provider'
import PatientService from '../services/patient'
import VoucherService from '../services/vouchers'
import store from '../store/index'
import AdminService from '@/services/admin'
export default {
  name: 'Home',
  components: { IqCard },
  mounted () {
    xray.index()
    this.getProviderCount()
    this.getPatientCount()
    this.getVoucherCount()
    this.currentUser = store.getters['User/getAccount']
    this.changePasswordPayload.admin_username = this.currentUser.admin_username
  },
  data () {
    return {
      changePasswordPayload: {
        admin_username: '',
        admin_oldPassword: '',
        admin_newPassword: ''
      },
      admin_repeat_newPassword: '',
      currentUser: null,
      voucherCount: 0,
      doctorCount: 0,
      patientCount: 0,
      bookingCount: 0
    }
  },
  methods: {
    AdminChangePassword () {
      console.log('payload : ', this.changePasswordPayload)
      if (this.changePasswordPayload.admin_newPassword !== this.admin_repeat_newPassword) {
        this.errorPasswordDontMatchToast()
      } else {
        AdminService.change_admin_password(this.changePasswordPayload)
          .then((response) => {
            console.log('response : ', response)
            this.successPasswordChangeToast()
          })
          .catch((error) => {
            console.log('password change error : ', error)
            this.errorPasswordChangeToast()
          })
          .finally(() => {
            console.log('finally executed')
          })
      }
    },
    successPasswordChangeToast () {
      this.$bvToast.toast('Password changed successfully', {
        title: `Password Changed`,
        variant: 'success',
        solid: true
      })
    },
    errorPasswordDontMatchToast () {
      this.$bvToast.toast('Error new passwords dont match', {
        title: `Error New Passwords Dot Match`,
        variant: 'danger',
        solid: true
      })
    },
    errorPasswordChangeToast () {
      this.$bvToast.toast('Error trying to change password', {
        title: `Error Password Change`,
        variant: 'danger',
        solid: true
      })
    },
    getVoucherCount () {
      VoucherService.get_voucher_count()
        .then((response) => {
          console.log('response voucher count : ', response.data)
          this.voucherCount = response.data
        })
        .catch((error) => {
          console.log('error getting voucher count : ', error)
        })
    },
    getPatientCount () {
      PatientService.get_patient_count()
        .then((response) => {
          console.log('response patient count : ', response.data)
          this.patientCount = response.data
        })
        .catch((error) => {
          console.log('error getting patient count : ', error)
        })
    },
    getProviderCount () {
      ProviderService.get_provider_count()
        .then((response) => {
          console.log('response provider count : ', response.data)
          this.doctorCount = response.data
        })
        .catch((error) => {
          console.log('error getting provider count : ', error)
        })
    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
