<template>
  <b-container fluid>
    <form>
      <b-row>

        <b-col lg="12">
          <div class="iq-edit-list-data">
            <tab-content id="pills-tabContent-2">
              <tab-content-item :active="true" id="personal-information" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Profile Information</h4>
                  </template>
                  <template v-slot:body>
                      <b-row align-v="center">
                        <b-form-group class="col-md-6" label="First Name" label-for="first_name">
                          <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.first_name" type="text" placeholder="First Name" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>

                        <b-form-group class="col-md-6" label="Last Name" label-for="last_name">
                          <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.last_name" type="text" placeholder="Last Name" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                        <b-form-group class="col-md-6" label="Username" label-for="username">
                          <ValidationProvider name="User name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.admin_username" type="text" placeholder="Username" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                        <b-form-group class="col-md-6" label="Admin Type" label-for="Admin Type">
                          <ValidationProvider name="User name" rules="required" v-slot="{ errors }">
                            <b-form-input readonly :value="user.admin_type == 1 ? 'Super Admin' : 'Admin Viewer'" type="text" placeholder="Username" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                      </b-row>
                      <b-row class="d-flex  flex-row-reverse">
                        <b-button type="button" variant="primary" class="mr-2" @click="handleUpdate">Submit</b-button>
                        <b-button type="reset" variant="none" class="iq-bg-danger">Cancel</b-button>
                      </b-row>
                  </template>
                </iq-card>
              </tab-content-item>
            </tab-content>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import store from '../../store/index'

export default {
  name: 'Doctor-profile',
  mounted () {
    xray.index()
    this.user = store.getters['User/getAccount']
  },
  data () {
    return {
      user: {}
    }
  },
  methods: {
    handleUpdate () {
      // apiClient.patch('admin/update_provider_account', {
      //   'provider_id': '41',
      //   'first_name': 'Team',
      //   'last_name': 'We Code',
      //   'phone_number': '0749901515',
      //   'reg_id': 'asd',
      //   'username': 'team@we-code.co.za',
      //   'email': 'team@we-code.co.za',
      //   'profileImage': '',
      //   'bio': '',
      //   'address': '287 Third avenue',
      //   'city': 'Cape Town',
      //   'postal_code': '7543',
      //   'professional_registration_number': 'asd',
      //   'department': 'asd',
      //   'registration_documents': '',
      //   'fica_documents': '',
      //   'practice_number': 'asd',
      //   'practice_number_phone': '',
      //   'user_title': null,
      //   'user_type': null,
      //   'profile_status': 1,
      //   'experience': 'asd',
      //   'accept_zlto': 0
      // }).then(response => console.log('>>>', response))
    }
  }
}
</script>
