import apiClient from '@config/apiClient'
export default {
  update_patient_profile_status (payload) {
    return apiClient.patch('/patient/update_patient_profile_status', payload)
  },
  get_all_patient_accounts () {
    return apiClient.get('patient/get_all_patients')
  },
  get_patient_count () {
    return apiClient.get('patient/get_count_patients')
  },
  delete_patient (id) {
    return apiClient.put('admin/delete_provider_account', { patient_id: id })
  }
}
