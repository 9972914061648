import apiSecureClient from '../config/apiSecureClient'
export default {
  get_all_admin_accounts () {
    return apiSecureClient.get('/admin/get_all_admins')
  },
  get_all_charge_options () {
    return apiSecureClient.get('admin/get_available_charge_options')
  },
  change_admin_password (payload) {
    return apiSecureClient.patch('admin/change_password', payload)
  },
  create_charge_fee () {
    return apiSecureClient.post('admin/create_charge_option')
  },
  get_appointment_history () {
    return apiSecureClient.get('admin/get_appointment_history')
  }
}
