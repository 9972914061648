<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Report Logs</h4>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table mb-0 table-borderless">
                    <thead>
                    <tr>
                      <th scope="col">Practioner Id</th>
                      <th scope="col">Practioner Name</th>
                      <th scope="col">Billed Rate</th>
                      <th scope="col">Patient Name</th>
                      <th scope="col">Patient Id</th>
                      <th scope="col">Consult Date & Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="report in report_list"  :key="report.patient_id">
                      <td>{{ patient.first_name }} {{ patient.last_name }}</td>
                      <td>
                        {{ report.patient_age }}
                      </td>
                      <td>
                        {{ report.phone_number }}
                      </td>
                      <td>
                        {{ report.email }}
                      </td>
                      <td>
                        {{ report.city }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
// import apiClient from '@config/apiClient'
import IqCard from '@components/xray/cards/iq-card'

export default {
  name: 'Reports',
  components: { IqCard },

  data () {
    return {
      report_list: []
    }
  },
  mounted () {
    xray.index()
    // apiClient.get('admin/get_all_reports').then(response => (this.report_list = response.data))
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
